import React, { useEffect, useState } from 'react';
import NavbarLogo from '../views/components/NavbarLogo';

function Navbar({ activeSection }) {
    const [isNavVisible, setIsNavVisible] = useState(true);
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

    useEffect(() => {
        window.addEventListener("scroll", toggleNavbar);
        return () => window.removeEventListener("scroll", toggleNavbar);
    }, []);

    const toggleNavbar = () => {
        if (window.scrollY > 0 || window.scrollY === 0) {
            setIsNavVisible(true);
        } else {
            setIsNavVisible(false);
        }
    };

    const headerClass = isNavVisible ? "opacity-100" : "opacity-0 border-b border-slate-100";
    const navClass = `transition-opacity duration-300 ${headerClass}`;

    const navItems = [
        { id: 'why', label: 'Pourquoi BeZeyn' },
        { id: 'services', label: 'Services' },
        { id: 'pro', label: 'Pro' }
    ];

    const toggleMobileMenu = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen);
    };

    return (
        <div>
            <header className={`w-full flex items-center justify-center h-[80px] fixed inset-0 z-50 backdrop-blur-sm bg-white/90 ${navClass}`}>
                <nav className="flex w-full justify-between items-center gap-8 px-6 transition-all duration-200 ease-in-out lg:px-32 py-4">
                    <div className="relative flex items-center">
                        <a href="/">
                            <NavbarLogo />
                        </a>
                    </div>
                    <ul className="hidden items-center justify-center gap-6 md:flex">
                        {navItems.map((item) => (
                            <div key={item.id} className='flex flex-col items-center'>
                                <li className={`cursor-pointer pt-1.5 font-dm text-sm font-medium ${activeSection === item.id ? 'text-[#D1238E]' : 'text-[#333333]'}`}>
                                    <a href={`#${item.id}`} className='font-semibold'>{item.label}</a>
                                </li>
                                {activeSection === item.id && (
                                    <svg xmlns="http://www.w3.org/2000/svg" className="rounded-full bg-[#D3208B]" viewBox="0 0 24 24" width="7" height="7" color="#D3208B" fill="none">
                                        <circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="1.5" />
                                    </svg>
                                )}
                            </div>
                        ))}
                    </ul>
                    <div className="hidden items-center justify-center gap-6 md:flex">
                        <button className='bg-gradient-to-r from-purple-500 rounded-[50px] to-orange-500 px-[20px] py-[11px]'>
                            <div className="flex flex-row items-center gap-2">
                                <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M13.5 15.5H7.5C7.23478 15.5 6.98043 15.3946 6.79289 15.2071C6.60536 15.0196 6.5 14.7652 6.5 14.5V13H7.5V14.5H13.5V2.5H7.5V4H6.5V2.5C6.5 2.23478 6.60536 1.98043 6.79289 1.79289C6.98043 1.60536 7.23478 1.5 7.5 1.5H13.5C13.7652 1.5 14.0196 1.60536 14.2071 1.79289C14.3946 1.98043 14.5 2.23478 14.5 2.5V14.5C14.5 14.7652 14.3946 15.0196 14.2071 15.2071C14.0196 15.3946 13.7652 15.5 13.5 15.5Z" fill="#F2F2F2" stroke="#F2F2F2" strokeWidth="0.5" />
                                    <path d="M7.795 10.795L9.585 9H2.5V8H9.585L7.795 6.205L8.5 5.5L11.5 8.5L8.5 11.5L7.795 10.795Z" fill="#F2F2F2" stroke="#F2F2F2" strokeWidth="0.5" />
                                </svg>
                                <h1 className="text-[14px] font-bold text-white">Télécharger l'app</h1>
                            </div>
                        </button>
                    </div>
                    <div className="relative flex items-center justify-center md:hidden">
                        <button type="button" onClick={toggleMobileMenu}>
                            {isMobileMenuOpen ? (
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-6 w-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            ) : (
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true" className="h-6 w-auto text-slate-900">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"></path>
                                </svg>
                            )}
                        </button>
                    </div>
                </nav>
            </header>
            {/* Mobile menu */}
            {isMobileMenuOpen && (
                <div className="fixed inset-0 w-full z-40 md:hidden">
                    <div className="fixed inset-0 bg-black bg-opacity-25" aria-hidden="true"></div>
                    <nav className="fixed h-fit right-0 z-40 w-full overflow-y-auto bg-white/90 backdrop-blur-sm py-6 px-6 transition-all duration-300 ease-in-out transform translate-x-0">
                        <div className="flex items-center justify-between mt-8">
                            <button type="button" className="-m-2.5 rounded-md p-2.5 text-gray-700" onClick={toggleMobileMenu}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-6 w-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </button>
                        </div>
                        <ul className="space-y-4 mt-10  ">
                            {navItems.map((item) => (
                                <li key={item.id} className={`cursor-pointer font-dm text-sm font-medium ${activeSection === item.id ? 'text-[#D1238E]' : 'text-[#333333]'}`}>
                                    <a href={`#${item.id}`} className='font-semibold' onClick={toggleMobileMenu}>{item.label}</a>
                                </li>
                            ))}
                        </ul>
                        <div className="mt-8">
                            <button className='w-full bg-gradient-to-r from-purple-500 rounded-[50px] to-orange-500 px-[20px] py-[11px]'>
                                <div className="flex flex-row items-center justify-center gap-2">
                                    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M13.5 15.5H7.5C7.23478 15.5 6.98043 15.3946 6.79289 15.2071C6.60536 15.0196 6.5 14.7652 6.5 14.5V13H7.5V14.5H13.5V2.5H7.5V4H6.5V2.5C6.5 2.23478 6.60536 1.98043 6.79289 1.79289C6.98043 1.60536 7.23478 1.5 7.5 1.5H13.5C13.7652 1.5 14.0196 1.60536 14.2071 1.79289C14.3946 1.98043 14.5 2.23478 14.5 2.5V14.5C14.5 14.7652 14.3946 15.0196 14.2071 15.2071C14.0196 15.3946 13.7652 15.5 13.5 15.5Z" fill="#F2F2F2" stroke="#F2F2F2" strokeWidth="0.5" />
                                        <path d="M7.795 10.795L9.585 9H2.5V8H9.585L7.795 6.205L8.5 5.5L11.5 8.5L8.5 11.5L7.795 10.795Z" fill="#F2F2F2" stroke="#F2F2F2" strokeWidth="0.5" />
                                    </svg>
                                    <h1 className="text-[14px] font-bold text-white">Télécharger l'app</h1>
                                </div>
                            </button>
                        </div>
                    </nav>
                </div>
            )}
        </div>
    )
}

export default Navbar