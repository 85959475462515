import React, { useState } from 'react';
import photo1 from "../assets/images/pic1.png";
import photo2 from "../assets/images/pic2.png"
import photo3 from "../assets/images/pic3.png"
import photo4 from "../assets/images/pic4.png"
import photo5 from "../assets/images/pic5.png"
import photo6 from "../assets/images/pic6.png"
import photo7 from "../assets/images/pic7.png"
import photo8 from "../assets/images/pic8.png"
import photo9 from "../assets/images/pic9.png"
import photo10 from "../assets/images/pic10.png"
import spa from "../assets/Icons/spa.png"
import coiffure from "../assets/Icons/coiffure.png"
import onglerie from "../assets/Icons/onglerie.png"
import therapie from "../assets/Icons/therapie.png"
import epilation from "../assets/Icons/epilation.png"
import corps from "../assets/Icons/corp.png"
import barbier from "../assets/Icons/barbier.png"
import "../App.css"



const BeautyCarousel = () => {

    const [transitioning, setTransitioning] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState('Spa');
    const categories = [
        {
            name: 'Spa',
            icon: spa
            ,
            bgColor: '#F9B689',
        },
        {
            name: 'Coiffure',
            icon:
                coiffure
            ,
            bgColor: '#FB7CAA',
        },
        {
            name: 'Onglerie',
            icon: onglerie
            ,
            bgColor: '#C97FEB',
        },
        {
            name: 'Thérapie',
            icon: therapie
            ,
            bgColor: '#D2E460',
        },
        {
            name: 'Épilation',
            icon: epilation
            ,
            bgColor: '#077FA5',
        },
        {
            name: 'Corps',
            icon:
                corps
            ,
            bgColor: '#7342FC',
        },
        {
            name: ' Barbier',
            icon: barbier
            ,
            bgColor: '#8F1966',
        },
    ];

    const currentIndex = categories.findIndex(cat => cat.name === selectedCategory);

    const handlePrevious = () => {
        setTransitioning(true);
        setTimeout(() => {
            const newIndex = (currentIndex - 1 + categories.length) % categories.length;
            setSelectedCategory(categories[newIndex].name);
            setTransitioning(false);
        }, 300);
    };

    const handleNext = () => {
        setTransitioning(true);
        setTimeout(() => {
            const newIndex = (currentIndex + 1) % categories.length;
            setSelectedCategory(categories[newIndex].name);
            setTransitioning(false);
        }, 300);
    };

    const handleCategoryClick = (category) => {
        setSelectedCategory(category);
        setTransitioning(true);
        setTimeout(() => {

            setTransitioning(false);
        }, 300);
    };

    const renderImages = () => {
        switch (selectedCategory) {
            case 'Spa':
                return [
                    `${photo1}`,
                    `${photo2}`,
                ];
            case 'Coiffure':
                return [
                    `${photo3}`,
                    `${photo4}`,
                ];
            case 'Onglerie':
                return [
                    `${photo5}`,
                    `${photo6}`,
                ];
            case 'Thérapie':
                return [
                    `${photo7}`,
                    `${photo8}`,
                ];
            case 'Épilation':
                return [
                    `${photo9}`,
                    `${photo10}`,
                ];
            default:
                return [];
        }
    };

    return (
        <div className=" mt-3 md:mt-0  h-fit py-1 lg:px-32 relative md:mb-5 px-4   flex items-center justify-center w-full ">
            <div className="flex flex-col w-full h-full">


                {/* Section du carrousel */}
                <div className="flex justify-between items-center mb-4">
                    <div className='flex mb-4 md:mb-14 flex-col items-start'>
                        <h1 className='text-center mb-1 tracking-widest text-[#9289E4]' style={{ fontFamily: 'Poppins, sans-serif', fontWeight: 600 }}>
                            Nos salons
                        </h1>
                        <h1 className='text-left text-3xl md:text-4xl text-[#000000]' style={{ fontFamily: 'Poppins, sans-serif', fontWeight: 700 }}>
                            Tous vos soins beauté
                        </h1>
                        <h1 className='text-center text-3xl md:text-4xl text-[#000000]' style={{ fontFamily: 'Poppins, sans-serif', fontWeight: 700 }}>
                            réunis
                        </h1>
                    </div>
                    <div className="flex space-x-5 md:py-3">
                        <button onClick={handlePrevious} className="px-5 py-3 bg-gray-200 focus:bg-pink-500 rounded-full shadow-md text-black focus:text-white">❮</button>
                        <button onClick={handleNext} className="px-5 py-3 bg-gray-200 focus:bg-pink-500 rounded-full shadow-md text-black focus:text-white">❯</button>
                    </div>
                </div>
                {/* Section des catégories */}
                <div className="flex h-full md:flex-row flex-col-reverse w-full">
                    <div className="md:w-1/3 flex h-fit mb-3 items-start justify-center md:h-[520px] custom-scrollbar overflow-y-auto w-full">
                        <div className=" flex flex-row md:flex-nowrap flex-wrap md:flex-col w-full items-center justify-center md:justify-start p-0 mt-2 gap-2 md:gap-1  h-fit">
                            {categories.map((category) => (
                                <div key={category.name} onClick={() => handleCategoryClick(category.name)} className={`flex mt-4 w-[100px] h-[100px] md:h-[80px]  px-5 py-3  md:py-2 md:w-[70%]    ${selectedCategory === category.name
                                    ? 'bg-[#FAE9F4] text-black'
                                    : 'bg-white'
                                    } hover:bg-[#FAE9F4] cursor-pointer  rounded-full flex-col md:flex-row items-center md:justify-start md:space-x-8`}>
                                    <button

                                        className={`flex md:ml-4 md:flex-row flex-col items-center  justify-center  w-14 h-14 rounded-full  transition `}

                                    >
                                        <img src={category.icon} className='w-[90px] h-[90px] object-cover' alt={category.name} />
                                    </button>
                                    <span className='text-[17px]  ' style={{ fontFamily: 'Poppins, sans-serif', fontWeight: 500 }}>{category.name}</span>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className='md:w-2/3  flex items-center justify-center w-full'>
                        <div className="w-full md:w-fit grid grid-cols-2 gap-4">
                            {renderImages().map((image, index) => (
                                <div key={index} className={`relative overflow-hidden rounded-lg shadow-md transition-opacity duration-300 ease-in-out ${transitioning ? 'opacity-0' : 'opacity-100'}`}>
                                    <img src={image} alt={`${selectedCategory}`} className=" w-full h-full object-cover" />
                                    <button className="absolute bottom-2 left-2   text-white py-1  flex flex-row gap-x-3 text-[18px] px-3 rounded-md" style={{ fontFamily: 'Poppins, sans-serif', fontWeight: 500 }}>
                                        Réserver ici
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M9.005 4L17.005 12L9.005 20L7 18L13.005 12L7 6L9.005 4Z" fill="white" />
                                        </svg>

                                    </button>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

            </div>
        </div >
    );
};

export default BeautyCarousel;
