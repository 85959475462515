import React, { useState, useEffect } from 'react'
import Navbar from '../layout/Navbar'
import HeroSection from '../layout/HeroSection'
import Service from '../layout/Service'
import BeautyCarousel from '../layout/BeautyCarousel'
import Pro from '../layout/Pro'
import PubSection from '../layout/PubSection'
import Footer from '../layout/Footer'

function Home() {
    const [activeSection, setActiveSection] = useState('why');

    useEffect(() => {
        const handleScroll = () => {
            const sections = ['why', 'services', 'pro'];
            const currentSection = sections.find(section => {
                const element = document.getElementById(section);
                if (element) {
                    const rect = element.getBoundingClientRect();
                    return rect.top <= 100 && rect.bottom >= 100;
                }
                return false;
            });

            if (currentSection) {
                setActiveSection(currentSection);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <div>
            <Navbar activeSection={activeSection} />
            <div id="why">
                <HeroSection />
            </div>
            <div id="services">
                <Service />
            </div>
            <BeautyCarousel />
            <div id="pro">
                <Pro />
            </div>
            <PubSection />
            <Footer />
        </div>
    )
}

export default Home