import React from 'react'
import logoPro from "../assets/images/bezeynPro.png";
import reservation from "../assets/images/3.1 Réservations à venir calendrier.png";
import eye from "../assets/Icons/eye.png"
import person from "../assets/Icons/person.png"
import calendar from "../assets/Icons/calendar.png"
import stats from "../assets/Icons/stats.png"
import support from "../assets/Icons/support.png"
import cellphone from "../assets/Icons/cellphone.png"
import { Link } from 'react-router-dom';

function Pro() {
    return (
        <div className="md:mt-8 py-10 lg:px-32 relative  px-4  flex bg-gradient-to-b from-[#922D6C] to-[#AD3068] w-full h-fit">
            <div className='w-full h-full flex items-center justify-center md:justify-start flex-col'>
                <div className='flex flex-col items-center w-full'>
                    {/* Le logo pro */}
                    <img src={logoPro} className='w-[100px] h-[100px] md:w-[150px] md:h-[150px] object-cover' alt="BezeynPro" />
                    {/* Text  */}
                    <h1 className='text-center text-[31.25px] mb-1 tracking-widest text-[#ffffff]' style={{ fontFamily: 'Poppins, sans-serif', fontWeight: 700 }}>
                        Vous êtes un professionnel de la beauté ?
                    </h1>
                    <h1 className='md:px-[310px] w-full relative  text-center text-[17px] mb-1 tracking-widest text-[#ffffff]' style={{ fontFamily: 'Poppins, sans-serif', fontWeight: 500 }}>
                        Spécifiquement dédiée aux professionnels de la beauté et du bien être, l'application BeZeyn Pro vous permet de donnez de la visibilité à votre établissement et de gérer votre activité depuis votre Smartphone, tablette ou ordinateur.
                    </h1>
                </div>

                <div className='md:grid md:grid-cols-3 overflow-hidden flex flex-col  w-full h-fit mt-6 '>
                    <div className='flex flex-col md:mb-0 mb-16  gap-y-16'>
                        <div className='flex flex-col' data-aos="fade-right" data-aos-duration="2000">
                            <div className=' flex  justify-center md:justify-end mb-4 w-full'>
                                <img src={eye} alt="" className='h-[30px] w-[30px] object-cover' />
                            </div>
                            <h1 className='text-center  md:text-right uppercase text-[22px] mb-1 tracking-widest text-[#ffffff]' style={{ fontFamily: 'Poppins, sans-serif', fontWeight: 700 }}>
                                Meilleure couverture
                            </h1>
                            <h1 className=' w-full relative  text-center md:text-right text-[15px]  tracking-widest text-[#ffffff]' style={{ fontFamily: 'Poppins, sans-serif', fontWeight: 500 }}>
                                Donnez de la visibilité à
                            </h1>
                            <h1 className='w-full relative  text-center md:text-right text-[15px]  tracking-widest text-[#ffffff]' style={{ fontFamily: 'Poppins, sans-serif', fontWeight: 500 }}>
                                votre établissement
                            </h1>
                        </div>

                        <div className='flex flex-col' data-aos="fade-right" data-aos-duration="2000">
                            <div className=' flex justify-center md:justify-end mb-4 w-full'>
                                <img src={person} alt="" className='h-[30px] w-[30px] object-cover' />
                            </div>
                            <h1 className='text-center md:text-right uppercase text-[22px] mb-1 tracking-widest text-[#ffffff]' style={{ fontFamily: 'Poppins, sans-serif', fontWeight: 700 }}>
                                Gestion du personnel
                            </h1>
                            <h1 className=' w-full relative  text-center md:text-right text-[15px]  tracking-widest text-[#ffffff]' style={{ fontFamily: 'Poppins, sans-serif', fontWeight: 500 }}>
                                Gérez aisément vos employés
                            </h1>
                            <h1 className='w-full relative  text-center md:text-right text-[15px]  tracking-widest text-[#ffffff]' style={{ fontFamily: 'Poppins, sans-serif', fontWeight: 500 }}>
                                et différents services.
                            </h1>
                        </div>

                        <div className='flex flex-col' data-aos="fade-right" data-aos-duration="2000">
                            <div className=' flex justify-center md:justify-end mb-4 w-full'>
                                <img src={support} alt="" className='h-[30px] w-[30px] object-cover' />
                            </div>
                            <h1 className='text-center md:text-right uppercase text-[22px] mb-1 tracking-widest text-[#ffffff]' style={{ fontFamily: 'Poppins, sans-serif', fontWeight: 700 }}>
                                support 24/7
                            </h1>
                            <h1 className=' w-full relative  text-center md:text-right text-[15px]  tracking-widest text-[#ffffff]' style={{ fontFamily: 'Poppins, sans-serif', fontWeight: 500 }}>
                                Nos équipes sont toujours
                            </h1>
                            <h1 className='w-full relative  text-center md:text-right text-[15px]  tracking-widest text-[#ffffff]' style={{ fontFamily: 'Poppins, sans-serif', fontWeight: 500 }}>
                                prêtes à vous accompagner.
                            </h1>
                        </div>
                    </div>
                    <div className='flex flex-row w-full justify-center' data-aos="fade-up" data-aos-duration="2000">
                        <img src={reservation} className='w-[290px] h-[600px]' alt="" />
                    </div>
                    <div className='flex flex-col md:mt-0 mt-16 gap-y-16'>
                        <div className='flex flex-col' data-aos="fade-left" data-aos-duration="2000">
                            <div className=' flex justify-center md:justify-start mb-4 w-full'>
                                <img src={calendar} alt="" className='h-[30px] w-[30px] object-cover' />
                            </div>
                            <h1 className='text-center md:text-left uppercase text-[22px] mb-1 tracking-widest text-[#ffffff]' style={{ fontFamily: 'Poppins, sans-serif', fontWeight: 700 }}>
                                Réservation
                            </h1>
                            <h1 className=' w-full relative  text-center md:text-left text-[15px]  tracking-widest text-[#ffffff]' style={{ fontFamily: 'Poppins, sans-serif', fontWeight: 500 }}>
                                Gérez les réservations de vos clients
                            </h1>
                            <h1 className='w-full relative  text-center md:text-left text-[15px]  tracking-widest text-[#ffffff]' style={{ fontFamily: 'Poppins, sans-serif', fontWeight: 500 }}>
                                simplement, avec une vue globale.
                            </h1>
                        </div>

                        <div className='flex flex-col' data-aos="fade-left" data-aos-duration="2000">
                            <div className=' flex justify-center md:justify-start mb-4 w-full'>
                                <img src={cellphone} alt="" className='h-[30px] w-[30px] object-cover' />
                            </div>
                            <h1 className='text-center md:text-left uppercase text-[22px] mb-1 tracking-widest text-[#ffffff]' style={{ fontFamily: 'Poppins, sans-serif', fontWeight: 700 }}>
                                ioS & android version
                            </h1>
                            <h1 className=' w-full relative  text-center md:text-left text-[15px]  tracking-widest text-[#ffffff]' style={{ fontFamily: 'Poppins, sans-serif', fontWeight: 500 }}>
                                Disponible sur IOS et ANDROID.
                            </h1>
                        </div>

                        <div className='flex flex-col' data-aos="fade-left" data-aos-duration="2000">
                            <div className=' flex justify-center md:justify-start mb-4 w-full'>
                                <img src={stats} alt="" className='h-[30px] w-[30px] object-cover' />
                            </div>
                            <h1 className='text-center md:text-left uppercase text-[22px] mb-1 tracking-widest text-[#ffffff]' style={{ fontFamily: 'Poppins, sans-serif', fontWeight: 700 }}>
                                Statistiques et
                                Tableau de Bord
                            </h1>
                            <h1 className=' w-full relative  text-center md:text-left text-[15px]  tracking-widest text-[#ffffff]' style={{ fontFamily: 'Poppins, sans-serif', fontWeight: 500 }}>
                                Analysez vos rapports financiers,
                            </h1>
                            <h1 className='w-full relative  text-center md:text-left text-[15px]  tracking-widest text-[#ffffff]' style={{ fontFamily: 'Poppins, sans-serif', fontWeight: 500 }}>
                                les performances de vos employés et
                                le résumé de vos rendez vous journaliers
                            </h1>
                            <h1 className='w-full relative  text-center md:text-left text-[15px]  tracking-widest text-[#ffffff]' style={{ fontFamily: 'Poppins, sans-serif', fontWeight: 500 }}>
                                le résumé de vos rendez vous journaliers
                            </h1>
                        </div>
                    </div>
                </div>

                <div className='mt-3 w-full flex flex-row items-center p-3 justify-center'>
                    <div className='md:flex-row w-full justify-center  flex gap-x-1 md:gap-x-6'>
                        <Link
                            to="#"
                            className="inline-flex overflow-hidden h-12 w-full md:w-44 items-center justify-center gap-2 whitespace-nowrap rounded bg-slate-900 px-3 text-base font-semibold tracking-wide text-slate-50 transition duration-300 hover:bg-slate-950 focus:bg-slate-950 focus-visible:outline-none"
                        >
                            <svg
                                width="40"
                                height="40"
                                className="w-14 h-14 mt-5 transform translate-x-1 "
                                viewBox="0 0 40 40"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                role="img"
                                aria-label="Google play icon"
                            >
                                <path fill="#ffc107" d="m23 12c0 .75-.42 1.41-1.03 1.75l-5.2 2.89-4.4-4.64 4.4-4.64 5.2 2.89c.61.34 1.03 1 1.03 1.75z"></path>
                                <path fill="#03a9f4" d="m12.37 12-10.8 11.39c-.36-.36-.57-.85-.57-1.39v-20c0-.54.21-1.03.57-1.39z"></path>
                                <path fill="#f44336" d="m12.37 12 4.4 4.64-12.8 7.11c-.29.16-.62.25-.97.25-.56 0-1.07-.23-1.43-.61z"></path>
                                <path fill="#4caf50" d="m16.77 7.36-4.4 4.64-10.8-11.39c.36-.38.87-.61 1.43-.61.35 0 .68.09.97.25z"></path>
                            </svg>

                            <span className="transform -translate-x-4  flex flex-col items-center">
                                <span className="text-xs font-normal">Télécharger sur</span>
                                <span>Google Play</span>
                            </span>
                        </Link>
                        <Link to="#" className="inline-flex h-12 w-full md:w-44  items-center justify-start gap-2 whitespace-nowrap rounded bg-slate-900 px-3 text-base font-semibold tracking-wide text-slate-50 transition duration-300 hover:bg-slate-950 focus:bg-slate-950 focus-visible:outline-none">
                            <svg width="40" height="40" className="w-10 h-10" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" role="graphics-symbol" aria-label="App store icon">
                                <path d="M30.293 21.0245C30.2769 18.0748 31.6111 15.8485 34.3116 14.209C32.8006 12.047 30.518 10.8575 27.5041 10.6244C24.6509 10.3993 21.5325 12.2881 20.3912 12.2881C19.1856 12.2881 16.4208 10.7047 14.2508 10.7047C9.76604 10.7771 5 14.2813 5 21.4103C5 23.516 5.38578 25.6914 6.15735 27.9365C7.18611 30.8861 10.8993 38.1196 14.7732 37.999C16.7986 37.9508 18.2292 36.5603 20.8654 36.5603C23.4212 36.5603 24.7473 37.999 27.0058 37.999C30.9119 37.9427 34.2714 31.3683 35.2519 28.4106C30.0117 25.9432 30.293 21.1772 30.293 21.0245ZM25.7439 7.82744C27.9381 5.2234 27.7372 2.85243 27.6729 2.00049C25.7359 2.11301 23.4935 3.31859 22.2156 4.80546C20.8091 6.39682 19.9813 8.36593 20.1581 10.5842C22.2558 10.7449 24.1687 9.66795 25.7439 7.82744Z" fill="currentColor" />
                            </svg>
                            <span className="flex flex-col">
                                <span className="text-xs font-normal">Télécharger sur</span>
                                <span>App Store</span>
                            </span>
                        </Link>
                    </div >
                </div>
            </div>

        </div >
    );
}

export default Pro
