import React from 'react'
import "@fontsource/poppins";
import "@fontsource/poppins/700.css";
import { Link } from "react-router-dom"
import sectionImage from "../assets/images/Group 48095437.png"
function HeroSection() {
    return (
        <div className="md:mt-14  lg:px-32 py-4 px-4 mt-28 overflow-hidden flex items-center justify-center w-full h-fit md:h-screen">
            <div className='flex flex-col-reverse items-center md:grid w-full   md:grid-cols-2'>
                <div className='w-full sm:-mt-5 mt-9 flex gap-[20px] flex-col'>
                    <div className='overflow-hidden  sm:-mt-12 bg-[#cabdff51] w-fit h-fit px-3 py-2 rounded-2xl'>
                        <h1 className='text-[13px] text-[#9289E4]' style={{ fontFamily: 'Poppins, sans-serif', fontWeight: 500 }}>
                            Réservation 24/24h
                        </h1>
                    </div>
                    <div className='text-3xl md:text-[3.3rem] w-full flex leading-[25px] sm:leading-[40px] flex-col items-start gap-3'>
                        <h1 style={{ fontFamily: 'Poppins, sans-serif', fontWeight: 700 }}>
                            Trouver un
                        </h1>
                        <h1 style={{ fontFamily: 'Poppins, sans-serif', fontWeight: 700 }}>
                            salon près de
                        </h1>
                        <h1 style={{ fontFamily: 'Poppins, sans-serif', fontWeight: 700 }}>
                            <span className='text-[#D1238E]'>chez vous</span>
                        </h1>

                    </div>
                    <div className='flex text-sm md:text-[0.675rem ] flex-col'>
                        <h1 style={{ fontFamily: 'Poppins, sans-serif', fontWeight: 500 }}>
                            Vous aider à trouver les meilleurs salons autour de
                        </h1>
                        <h1 style={{ fontFamily: 'Poppins, sans-serif', fontWeight: 500 }}>
                            vous le plus rapidement possible.
                        </h1>
                    </div>
                    <div className='  md:flex-row w-full flex gap-x-1 md:gap-x-8'>
                        <Link
                            to="#"
                            className="inline-flex h-12 w-full md:w-44 overflow-hidden items-center justify-center gap-2 whitespace-nowrap rounded bg-slate-900 px-3 text-base font-semibold tracking-wide text-slate-50 transition duration-300 hover:bg-slate-950 focus:bg-slate-950 focus-visible:outline-none"
                        >
                            <svg
                                width="40"
                                height="40"
                                className="w-14 h-14 mt-5 transform translate-x-1 "
                                viewBox="0 0 40 40"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                role="img"
                                aria-label="Google play icon"
                            >
                                <path fill="#ffc107" d="m23 12c0 .75-.42 1.41-1.03 1.75l-5.2 2.89-4.4-4.64 4.4-4.64 5.2 2.89c.61.34 1.03 1 1.03 1.75z"></path>
                                <path fill="#03a9f4" d="m12.37 12-10.8 11.39c-.36-.36-.57-.85-.57-1.39v-20c0-.54.21-1.03.57-1.39z"></path>
                                <path fill="#f44336" d="m12.37 12 4.4 4.64-12.8 7.11c-.29.16-.62.25-.97.25-.56 0-1.07-.23-1.43-.61z"></path>
                                <path fill="#4caf50" d="m16.77 7.36-4.4 4.64-10.8-11.39c.36-.38.87-.61 1.43-.61.35 0 .68.09.97.25z"></path>
                            </svg>

                            <span className="transform -translate-x-4  flex flex-col items-center">
                                <span className="text-xs font-normal">Télécharger sur</span>
                                <span>Google Play</span>
                            </span>
                        </Link>

                        <Link to="#" className="inline-flex h-12 w-full md:w-44  items-center justify-start gap-2 whitespace-nowrap rounded bg-slate-900 px-3 text-base font-semibold tracking-wide text-slate-50 transition duration-300 hover:bg-slate-950 focus:bg-slate-950 focus-visible:outline-none">
                            <svg width="40" height="40" className="w-10 h-10" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" role="graphics-symbol" aria-label="App store icon">
                                <path d="M30.293 21.0245C30.2769 18.0748 31.6111 15.8485 34.3116 14.209C32.8006 12.047 30.518 10.8575 27.5041 10.6244C24.6509 10.3993 21.5325 12.2881 20.3912 12.2881C19.1856 12.2881 16.4208 10.7047 14.2508 10.7047C9.76604 10.7771 5 14.2813 5 21.4103C5 23.516 5.38578 25.6914 6.15735 27.9365C7.18611 30.8861 10.8993 38.1196 14.7732 37.999C16.7986 37.9508 18.2292 36.5603 20.8654 36.5603C23.4212 36.5603 24.7473 37.999 27.0058 37.999C30.9119 37.9427 34.2714 31.3683 35.2519 28.4106C30.0117 25.9432 30.293 21.1772 30.293 21.0245ZM25.7439 7.82744C27.9381 5.2234 27.7372 2.85243 27.6729 2.00049C25.7359 2.11301 23.4935 3.31859 22.2156 4.80546C20.8091 6.39682 19.9813 8.36593 20.1581 10.5842C22.2558 10.7449 24.1687 9.66795 25.7439 7.82744Z" fill="currentColor" />
                            </svg>
                            <span className="flex flex-col">
                                <span className="text-xs font-normal">Télécharger sur</span>
                                <span>App Store</span>
                            </span>
                        </Link>
                    </div >
                </div >
                <div className="relative flex justify-center items-center w-full h-full bg-white">
                    {/* <svg className='w-[250px] h-[250px] md:w-[350px] md:h-[350px] sm:w-[450px] sm:h-[450px]' viewBox="0 0 503 512" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M503 256C503 397.385 390.4 512 251.5 512C112.6 512 0 397.385 0 256C0 114.615 112.6 0 251.5 0C390.4 0 503 114.615 503 256Z" fill="url(#paint0_linear_953_4748)" />
                        <defs>
                            <linearGradient id="paint0_linear_953_4748" x1="-91.8813" y1="33.734" x2="390.143" y2="574.608" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#879AF2" />
                                <stop offset="0.655" stop-color="#D3208B" />
                                <stop offset="1" stop-color="#FDA000" />
                            </linearGradient>
                        </defs>
                    </svg>

                    <div className="absolute w-[250px] h-[250px] md:w-[350px] md:h-[350px] sm:w-[450px] sm:h-[450px] rounded-full overflow-hidden">

                        <img src={femme} alt="Profile" className="object-cover w-full h-full" />
                    </div>

                    <div className="flex  md:hidden lg:flex absolute top-0 left-0 transform md:translate-x-[80px] md:-translate-y-[10px]">
                        <div className="bg-pink-500 sm:p-4 p-1 mt-8 -rotate-12 rounded-xl shadow-lg">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 text-white" viewBox="0 0 24 24" fill="currentColor">
                                <path fill-rule="evenodd" d="M12 3a9 9 0 100 18 9 9 0 000-18zm0 2a7 7 0 100 14 7 7 0 000-14zm-.25 3a.75.75 0 011.5 0v4.25l2.5 2.5a.75.75 0 01-1.06 1.06l-3-3A.75.75 0 0111.75 13V8z" clip-rule="evenodd" />
                            </svg>
                        </div>
                    </div>
                    <div className="absolute flex md:hidden lg:flex bottom-0 right-0 transform translate-x-[15px] -translate-y-[150px] md:-translate-x-[50px] md:-translate-y-[200px]">
                        <svg className='w-[82px] h-[59px] sm:w-[82px] sm:h-[189px]' viewBox="0 0 82 149" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_953_4752)">
                                <path d="M49.0314 21.6782C49.0317 21.6785 49.032 21.6788 49.0324 21.679L57.3883 27.495C57.4844 27.5618 57.5964 27.6022 57.713 27.6121C57.8296 27.6219 57.9468 27.6009 58.0527 27.5511L67.0099 23.3317C67.8426 22.952 67.7724 21.7469 66.9006 21.468L41.3439 13.2915C41.167 13.2348 40.9777 13.2285 40.7974 13.2733C40.6171 13.3181 40.4528 13.4123 40.3231 13.5453C40.1933 13.6782 40.1031 13.8447 40.0627 14.0261C40.0222 14.2074 40.0331 14.3964 40.0941 14.572L48.8869 39.9232C49.1868 40.788 50.3942 40.828 50.7527 39.9874L54.753 30.9297C54.8006 30.8226 54.819 30.7048 54.8065 30.5882C54.794 30.4717 54.751 30.3605 54.6818 30.2659L48.664 22.0537C48.6273 22.0027 48.6097 21.9405 48.6144 21.878C48.6191 21.8154 48.6457 21.7565 48.6895 21.7116C48.7333 21.6667 48.7916 21.6387 48.854 21.6325C48.9163 21.6263 48.9786 21.6422 49.0303 21.6773C49.0307 21.6776 49.0311 21.6779 49.0314 21.6782Z" fill="#8F8DE7" />
                            </g>
                            <path d="M14.2222 147.5C34.2222 135.833 67.8222 106.3 42.2222 81.5C10.2222 50.5 -16.2778 118.5 18.2222 124.5C52.7222 130.5 107.222 87 64.2222 37" stroke="#8F8DE7" stroke-width="3" stroke-linejoin="round" stroke-dasharray="8 8" />
                            <defs>
                                <clipPath id="clip0_953_4752">
                                    <rect width="32" height="32" fill="white" transform="translate(49.8999 45.2515) rotate(-135.694)" />
                                </clipPath>
                            </defs>
                        </svg>

                    </div>
                    <div className="w-fit h-fit md:hidden lg:flex sm:w-fit sm:h-[100px] absolute -bottom-5 sm:bottom-8 -right-0  sm:-right-2 bg-white rounded-xl shadow-lg p-2 flex items-center space-x-2 sm:space-x-4">
                        <img src={illustSallon} alt="Salon" className="sm:py-2 py-0 w-[65px] h-[65px] sm:w-[96px] sm:h-[96px] rounded-lg object-cover" />
                        <div className="flex flex-col space-y-0">
                            <h3 className="font-semibold text-gray-800 text-xs md:text-md ">ZAHA Beauty</h3>
                            <h3 className="font-semibold text-gray-800 text-xs md:text-md ">Casablanca</h3>
                            <div className="flex items-center text-yellow-400">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-[20px] w-[20px]" fill="currentColor" viewBox="0 0 24 24">
                                    <path d="M12 4.8l1.64 3.52 3.86.56-2.8 2.73.66 3.85-3.46-1.82-3.46 1.82.66-3.85-2.8-2.73 3.86-.56L12 4.8z" />
                                </svg>
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-[20px] w-[20px]" fill="currentColor" viewBox="0 0 24 24">
                                    <path d="M12 4.8l1.64 3.52 3.86.56-2.8 2.73.66 3.85-3.46-1.82-3.46 1.82.66-3.85-2.8-2.73 3.86-.56L12 4.8z" />
                                </svg>
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-[20px] w-[20px]" fill="currentColor" viewBox="0 0 24 24">
                                    <path d="M12 4.8l1.64 3.52 3.86.56-2.8 2.73.66 3.85-3.46-1.82-3.46 1.82.66-3.85-2.8-2.73 3.86-.56L12 4.8z" />
                                </svg>
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-[20px] w-[20px]" fill="currentColor" viewBox="0 0 24 24">
                                    <path d="M12 4.8l1.64 3.52 3.86.56-2.8 2.73.66 3.85-3.46-1.82-3.46 1.82.66-3.85-2.8-2.73 3.86-.56L12 4.8z" />
                                </svg>
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-[20px] w-[20px]" fill="currentColor" viewBox="0 0 24 24">
                                    <path d="M12 4.8l1.64 3.52 3.86.56-2.8 2.73.66 3.85-3.46-1.82-3.46 1.82.66-3.85-2.8-2.73 3.86-.56L12 4.8z" />
                                </svg>
                            </div>
                            <div className="bg-pink-500 text-white py-1 px-3 rounded-lg text-sm flex gap-x-3 items-center mt-1">
                                <button className="text-xs md:text-md " style={{ fontFamily: 'Poppins, sans-serif', fontWeight: 500 }}>Réserver</button>
                                <svg width="13" height="17" viewBox="0 0 13 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M2.77999 1.18716L3.67234 0.918767C4.16117 0.77136 4.68694 0.806845 5.15153 1.0186C5.61612 1.23035 5.9878 1.60391 6.19721 2.06957L6.88693 3.60345C7.06698 4.00398 7.11711 4.45082 7.03031 4.88129C6.9435 5.31176 6.7241 5.70425 6.4029 6.00369L5.26051 7.06885C5.22712 7.09997 5.20597 7.14201 5.20087 7.18737C5.16723 7.49094 5.37292 8.08201 5.847 8.90401C6.19186 9.5012 6.5046 9.921 6.76994 10.1557C6.95498 10.3201 7.05668 10.3553 7.10026 10.3431L8.63721 9.87282C9.05694 9.74444 9.50632 9.75062 9.92235 9.89048C10.3384 10.0304 10.7002 10.2969 10.9572 10.6528L11.9367 12.0108C12.2345 12.4238 12.3724 12.9308 12.3248 13.4378C12.2771 13.9447 12.0471 14.4171 11.6775 14.7674L11 15.4097C10.6404 15.7502 10.1988 15.9917 9.71813 16.1109C9.23747 16.23 8.73414 16.2226 8.25718 16.0894C6.15133 15.5014 4.26341 13.7244 2.572 10.795C0.878303 7.86179 0.284169 5.33539 0.831659 3.21501C0.954902 2.73813 1.19891 2.30103 1.54016 1.94584C1.88142 1.59066 2.30841 1.32937 2.77999 1.18716ZM3.11108 2.28596C2.82822 2.37119 2.57206 2.52781 2.36729 2.74075C2.16252 2.95369 2.01603 3.21578 1.94193 3.50176C1.48161 5.28492 2.00846 7.52458 3.56528 10.2215C5.11982 12.9146 6.79364 14.4898 8.5661 14.9853C8.85218 15.065 9.15402 15.0692 9.44224 14.9977C9.73047 14.9261 9.99526 14.7812 10.2109 14.577L10.8891 13.9346C11.0572 13.7754 11.1618 13.5606 11.1834 13.3301C11.2051 13.0996 11.1423 12.8691 11.0069 12.6814L10.0281 11.3241C9.91132 11.1622 9.74674 11.0409 9.55749 10.9772C9.36823 10.9136 9.1638 10.9108 8.97289 10.9693L7.43212 11.4411C6.53748 11.7072 5.72542 10.9877 4.85372 9.4775C4.26647 8.46051 3.99272 7.67292 4.06078 7.06044C4.09672 6.74234 4.24429 6.44872 4.47828 6.23003L5.62067 5.16487C5.76673 5.02877 5.86651 4.85036 5.90601 4.65466C5.94551 4.45896 5.92273 4.25581 5.84088 4.07371L5.15117 2.53983C5.05599 2.32833 4.88715 2.15866 4.67613 2.06243C4.4651 1.9662 4.22629 1.94999 4.00419 2.0168L3.11108 2.28596Z" fill="#F2F2F2" />
                                </svg>

                            </div>
                        </div>
                    </div> */}

                    <img src={sectionImage} alt="" />

                </div>
            </div >
        </div >
    )
}

export default HeroSection
