import React from 'react'
import Service1 from '../views/components/Service1'
import Service2 from '../views/components/Service2'
import Service3 from '../views/components/Service3'

function Service() {
    return (
        <div className='w-full overflow-hidden flex-col items-center justify-center h-fit md:h-screen'>
            <div className='flex mb-4 md:mb-14 flex-col items-center'>
                <h1 className='text-center mb-1 tracking-widest text-[#9289E4]' style={{ fontFamily: 'Poppins, sans-serif', fontWeight: 600 }}>
                    Nos services
                </h1>
                <h1 className='text-center text-3xl md:text-4xl text-[#000000]' style={{ fontFamily: 'Poppins, sans-serif', fontWeight: 700 }}>
                    Vos services préferés
                </h1>
                <h1 className='text-center text-3xl md:text-4xl text-[#000000]' style={{ fontFamily: 'Poppins, sans-serif', fontWeight: 700 }}>
                    à portée de main
                </h1>
            </div>
            <div className="w-full overflow-hidden grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1">
                <div className='flex flex-col items-center' data-aos="fade-up"
                    data-aos-duration="1000">
                    <Service1 />

                    <div className='px-13'>
                        <h1 className='  text-[23px] text-[#000000]' style={{ fontFamily: 'Poppins, sans-serif', fontWeight: 700 }}>

                            <div className='flex flex-col mt-2 items-center'>
                                <div className='flex flex-row items-center'>
                                    <h1>Trouver les</h1>
                                    <span className=' mx-2  bg-[#cabdff51] text-[23px] text-[#9289E4] w-fit h-fit px-3 py-1 rounded-2xl' style={{ fontFamily: 'Poppins, sans-serif', fontWeight: 700 }}>
                                        meilleurs
                                    </span>
                                </div>
                                <h1>salons près de chez vous</h1>
                            </div>

                        </h1>

                    </div>
                </div>
                <div className='flex flex-col items-center' data-aos="fade-up"
                    data-aos-duration="1500">
                    <Service2 />
                    <div className='px-13'>
                        <h1 className='  text-[23px] text-[#000000]' style={{ fontFamily: 'Poppins, sans-serif', fontWeight: 700 }}>

                            <div className='flex flex-col items-center'>
                                <h1> Réservez à tout moments</h1>
                                <span className='mx-2  bg-[#cabdff51] text-[23px] text-[#9289E4] w-fit h-fit px-3 py-1 rounded-2xl' style={{ fontFamily: 'Poppins, sans-serif', fontWeight: 700 }}>
                                    24h/24 & 7j/7
                                </span>
                            </div>

                        </h1>

                    </div>
                </div>
                <div className='flex flex-col items-center' data-aos="fade-up"
                    data-aos-duration="2000">
                    <Service3 />
                    <div className='px-13'>
                        <h1 className=' flex flex-col mt-2 items-center text-[23px] text-[#000000]' style={{ fontFamily: 'Poppins, sans-serif', fontWeight: 700 }}>

                            <h1>Gérer vos rendez-vous</h1>
                            <span className='  bg-[#cabdff51] text-[23px] text-[#9289E4] w-fit h-fit px-3 py-1 rounded-2xl' style={{ fontFamily: 'Poppins, sans-serif', fontWeight: 700 }}>
                                simplement
                            </span>
                        </h1>

                    </div>
                </div>
            </div >


        </div >
    )
}

export default Service
