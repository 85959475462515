import React from 'react'
import { Link } from 'react-router-dom';
import NavbarLogo from '../views/components/NavbarLogo';
import FooterLogo from '../views/components/FooterLogo';
function Footer() {

    const footerNavs = [
        {
            href: '#why',
            name: 'Pourquoi BeZeyn'
        },
        {
            href: '#services',
            name: 'Services'
        },
        {
            href: '#pro',
            name: 'Pro'
        },
    ]
    return (
        <footer className="pt-10 bg-[#1E1D2D]" >
            <div className="px-4 text-gray-600 lg:px-32">
                <div className="justify-between  sm:flex">
                    <div className="space-y-6">
                        <FooterLogo />
                        <div className='flex items-start flex-col'>
                            <h1 className='text-center text-[16px]  text-[#ffffff6e]' style={{ fontFamily: 'Poppins, sans-serif', fontWeight: 400 }}>
                                Trouvez un salon rapidement
                            </h1>
                            <h1 className='text-center text-[16px]  text-[#ffffff6e]' style={{ fontFamily: 'Poppins, sans-serif', fontWeight: 400 }}>
                                près de chez vous.
                            </h1>
                        </div>

                    </div>
                    <div className='flex flex-col  mt-3 md:mt-0 items-start'>
                        <h1 className='text-center text-[25px] uppercase text-[#ffffff]' style={{ fontFamily: 'Poppins, sans-serif', fontWeight: 600 }}>
                            Liens Rapides
                        </h1>
                        <ul className="flex flex-wrap flex-col items-start gap-4 text-sm sm:text-base">
                            {
                                footerNavs.map((item, idx) => (
                                    <li className="text-[#ffffff6e] duration-150 hover:text-white">
                                        <a key={idx} href={item.href}>
                                            {item.name}
                                        </a>
                                    </li>
                                ))
                            }
                        </ul>
                    </div>

                    <div className='flex mt-3 md:mt-0  flex-col items-start justify-between gap-5'>
                        <h1 className='text-center text-[25px] uppercase text-[#ffffff]' style={{ fontFamily: 'Poppins, sans-serif', fontWeight: 600 }}>
                            NewsLetter
                        </h1>
                        <div className='flex items-start flex-col'>
                            <h1 className='text-center text-[16px] text-[#ffffff6e]' style={{ fontFamily: 'Poppins, sans-serif', fontWeight: 500 }}>
                                Inscrivez-vous à notre newsletter pour recevoir
                            </h1>
                            <h1 className='text-center text-[16px] text-[#ffffff6e]' style={{ fontFamily: 'Poppins, sans-serif', fontWeight: 500 }}>
                                toutes nos actualités.
                            </h1>
                        </div>

                        <div className=" bg-white flex items-center  w-full rounded-full border border-gray-300">
                            <input type="email" placeholder="Enter your email" className="w-full bg-transparent  py-3 px-3 text-gray-800 text-base border-none outline-none" />
                            <button className=" flex items-center justify-center bg-[#D3208B] text-white text-base font-semibold  p-3  transform -translate-x-1 rounded-full focus:outline-none">
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_953_4902)">
                                        <path d="M2.5 10.8334H7.5V9.16675H2.5V1.53842C2.50001 1.46602 2.51889 1.39487 2.55478 1.33198C2.59066 1.26909 2.64232 1.21665 2.70465 1.1798C2.76698 1.14296 2.83783 1.12299 2.91023 1.12188C2.98262 1.12076 3.05406 1.13852 3.1175 1.17342L18.5025 9.63509C18.5678 9.67106 18.6223 9.7239 18.6602 9.7881C18.6982 9.8523 18.7182 9.92551 18.7182 10.0001C18.7182 10.0747 18.6982 10.1479 18.6602 10.2121C18.6223 10.2763 18.5678 10.3291 18.5025 10.3651L3.1175 18.8268C3.05406 18.8617 2.98262 18.8794 2.91023 18.8783C2.83783 18.8772 2.76698 18.8572 2.70465 18.8204C2.64232 18.7835 2.59066 18.7311 2.55478 18.6682C2.51889 18.6053 2.50001 18.5342 2.5 18.4618V10.8334Z" fill="white" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_953_4902">
                                            <rect width="20" height="20" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>

                            </button>
                        </div>
                    </div>
                </div>
                <div className="mt-10 py-10 border-t md:text-center">
                    <h1 className='text-center text-[16px] text-[#ffffff6e]' style={{ fontFamily: 'Poppins, sans-serif', fontWeight: 500 }}>
                        © Copyright 2024 .BeZeyn. Tous droits réservés.
                    </h1>
                </div>
            </div >
        </footer >
    );


}

export default Footer
