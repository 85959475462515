import React from 'react'
import cellphone from "../assets/images/replace_photo.jpg"
import "../App.css";
import { Link } from 'react-router-dom';

function PubSection() {
    return (
        <div className='overflow-hidden p-4  lg:px-32 w-full md:h-screen h-fit relative flex items-center justify-center'>
            <div className='bgPub md:py-16 rounded-[30px] w-full h-auto md:h-[550px]'>
                <div className='overflow-hidden flex flex-col md:items-start items-center w-full pt-5 px-5 md:p-12 gap-6'>
                    <h1 className='text-center md:text-left text-[18px] mb-1 text-[#ffffff]' style={{ fontFamily: 'Poppins, sans-serif', fontWeight: 600 }}>
                        Télécharger notre application
                    </h1>
                    <h1 className='w-full relative text-center md:text-left text-[32px] md:text-[46px] text-[#ffffff]' style={{ fontFamily: 'Poppins, sans-serif', fontWeight: 700 }}>
                        Commencez maintenant !
                    </h1>
                    <div className='flex flex-col'>
                        <h1 className='w-full relative text-center md:text-left text-[16px] md:text-[18px] text-[#ffffff]' style={{ fontFamily: 'Poppins, sans-serif', fontWeight: 500 }}>
                            Nous simplifions vos réservations pour
                        </h1>
                        <h1 className='w-full relative text-center md:text-left text-[16px] md:text-[18px] text-[#ffffff]' style={{ fontFamily: 'Poppins, sans-serif', fontWeight: 500 }}>
                            différents services de beauté
                        </h1>
                    </div>

                    <div className='md:flex-row w-full justify-center md:justify-start hidden md:flex gap-4 md:gap-6'>
                        <Link
                            to="#"
                            className="inline-flex overflow-hidden h-12 w-full md:w-44 items-center justify-center gap-2 whitespace-nowrap rounded bg-slate-900 px-3 text-base font-semibold tracking-wide text-slate-50 transition duration-300 hover:bg-slate-950 focus:bg-slate-950 focus-visible:outline-none"
                        >
                            <svg
                                width="40"
                                height="40"
                                className="w-14 h-14 mt-5 transform translate-x-1 "
                                viewBox="0 0 40 40"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                role="img"
                                aria-label="Google play icon"
                            >
                                <path fill="#ffc107" d="m23 12c0 .75-.42 1.41-1.03 1.75l-5.2 2.89-4.4-4.64 4.4-4.64 5.2 2.89c.61.34 1.03 1 1.03 1.75z"></path>
                                <path fill="#03a9f4" d="m12.37 12-10.8 11.39c-.36-.36-.57-.85-.57-1.39v-20c0-.54.21-1.03.57-1.39z"></path>
                                <path fill="#f44336" d="m12.37 12 4.4 4.64-12.8 7.11c-.29.16-.62.25-.97.25-.56 0-1.07-.23-1.43-.61z"></path>
                                <path fill="#4caf50" d="m16.77 7.36-4.4 4.64-10.8-11.39c.36-.38.87-.61 1.43-.61.35 0 .68.09.97.25z"></path>
                            </svg>

                            <span className="transform -translate-x-4  flex flex-col items-center">
                                <span className="text-xs font-normal">Télécharger sur</span>
                                <span>Google Play</span>
                            </span>
                        </Link>
                        <Link to="#" className="inline-flex h-12 w-full md:w-44 items-center justify-start gap-2 whitespace-nowrap rounded bg-slate-900 px-3 text-base font-semibold tracking-wide text-slate-50 transition duration-300 hover:bg-slate-950">
                            <svg width="40" height="40" className="w-10 h-10" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M30.293 21.0245C30.2769 18.0748 31.6111 15.8485 34.3116 14.209C32.8006 12.047 30.518 10.8575 27.5041 10.6244C24.6509 10.3993 21.5325 12.2881 20.3912 12.2881C19.1856 12.2881 16.4208 10.7047 14.2508 10.7047C9.76604 10.7771 5 14.2813 5 21.4103C5 23.516 5.38578 25.6914 6.15735 27.9365C7.18611 30.8861 10.8993 38.1196 14.7732 37.999C16.7986 37.9508 18.2292 36.5603 20.8654 36.5603C23.4212 36.5603 24.7473 37.999 27.0058 37.999C30.9119 37.9427 34.2714 31.3683 35.2519 28.4106C30.0117 25.9432 30.293 21.1772 30.293 21.0245ZM25.7439 7.82744C27.9381 5.2234 27.7372 2.85243 27.6729 2.00049C25.7359 2.11301 23.4935 3.31859 22.2156 4.80546C20.8091 6.39682 19.9813 8.36593 20.1581 10.5842C22.2558 10.7449 24.1687 9.66795 25.7439 7.82744Z" fill="currentColor" />
                            </svg>
                            <span className="flex flex-col">
                                <span className="text-xs font-normal">Télécharger sur</span>
                                <span>App Store</span>
                            </span>
                        </Link>
                    </div>
                    <div className='flex md:hidden' data-aos="fade-up"
                        data-aos-anchor-placement="center-bottom">
                        <img src={cellphone} alt="" className='w-[300px] rounded-tl-[29px] rounded-tr-[29px]  md:w-auto h-auto md:h-[500px] object-cover' />
                    </div>
                </div>
            </div>
            <div className='md:flex hidden  absolute right-0 bottom-0 md:right-52' data-aos="fade-up" data-aos-anchor-placement="center-bottom">
                <img
                    src={cellphone}
                    alt=""
                    className='w-[300px] cellphone md:w-auto h-auto md:h-[550px] object-cover '
                />
            </div>
            <div className='absolute md:flex hidden transform translate-x-[100px] -translate-y-[210px]'>
                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="-2" y="3.91663" width="14" height="14" rx="7" transform="rotate(-25 -2 3.91663)" fill="#FFFFFFFF" />
                </svg>
            </div>

            <div className='absolute md:flex hidden transform translate-x-[600px] translate-y-[215px]'>
                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="-2" y="3.91663" width="14" height="14" rx="7" transform="rotate(-25 -2 3.91663)" fill="#FFFFFFFF" />
                </svg>
            </div>
            <div className='absolute md:flex hidden transform translate-x-[200px] translate-y-[200px]'>
                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="-2" y="3.91663" width="14" height="14" rx="7" transform="rotate(-25 -2 3.91663)" fill="#FFFFFFFF" />
                </svg>
            </div>
            <div className='absolute md:flex hidden transform translate-x-[610px] -translate-y-[170px]'>
                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="-2" y="3.91663" width="14" height="14" rx="7" transform="rotate(-25 -2 3.91663)" fill="#FFFFFFFF" />
                </svg>
            </div>

        </div >
    )
}

export default PubSection
